// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./add_jquery"
import * as bootstrap from "bootstrap"
import "slick-carousel/slick/slick"


console.log("Logged from app/javascript/application.js");
$(function () {
    console.log("jQuery " + $().jquery + " is loaded");
    if (!$.fn.modal) {
        console.log('Bootstrap is not working');
    } else {
        console.log('Bootstrap ' + $().modal.Constructor.VERSION + ' is loaded');
    }
    if (typeof jQuery.ui != 'undefined') {
        console.log("jQuery-UI is loaded");
    } else {
        console.log("jQuery-UI is NOT loaded");
    }
})

